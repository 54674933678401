import { Controller } from '@hotwired/stimulus'

import type { ApplicationContext, ApplicationProps } from '@types'
import type { SvelteComponent } from 'svelte'

const layoutPaths = import.meta.glob('@scripts/layouts/*.svelte')
const layouts = Object.fromEntries(
  Object.entries(layoutPaths).map(([path, proxy]) => {
    const regexp = /\/javascripts\/([\w\/]+)\.svelte$/ //eslint-disable-line
    const matches = regexp.exec(path)
    const name = matches?.[1]
    return [name, proxy]
  })
)

const viewPaths = import.meta.glob('@views/**/*.svelte')
const views = Object.fromEntries(
  Object.entries(viewPaths).map(([path, proxy]) => {
    const regexp = /\/javascripts\/([\w\/]+)\.svelte$/ //eslint-disable-line
    const matches = regexp.exec(path)
    const name = matches?.[1]
    return [name, proxy]
  })
)

export default class extends Controller {
  static override values = {
    layoutComponent: String,
    layoutContext: Object,
    layoutProps: Object,

    contentComponent: String,
    contentProps: Object
  }

  declare readonly layoutComponentValue: string
  declare readonly layoutContextValue: ApplicationContext
  declare readonly layoutPropsValue: ApplicationProps

  declare readonly contentComponentValue: string
  declare readonly contentPropsValue: object

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  override async connect() {
    const layout = layouts[this.layoutComponentValue]
    const layoutComponent: typeof SvelteComponent = (await layout()).default

    const component = views[this.contentComponentValue]
    const contentComponent: typeof SvelteComponent = (await component()).default

    new layoutComponent({
      target: this.element.parentElement as Element,
      anchor: this.element,
      props: {
        layoutContext: this.layoutContextValue,
        layoutProps: this.layoutPropsValue,
        contentComponent,
        contentProps: this.contentPropsValue
      }
    })

    this.element.remove()
  }
}
