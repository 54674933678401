import { registerControllers } from 'stimulus-vite-helpers'

import { Application } from '@hotwired/stimulus'

const application = Application.start()
const controllers = import.meta.glob(
  '@scripts/controllers/**/*_controller.ts',
  {
    eager: true
  }
)
registerControllers(application, controllers)
